<template>
  <div>
    <div class="col-lg-8 col-md-8 pt-4">
      <card>
        <div class="p-4 border border-black">
          <h4 class="pb-4" style="padding-bottom: 20px">Add Enquiry :</h4>
          <form>
            <div class="row mb-3">
              <div class="col-3">
                <label for="date" class="col col-form-label"
                  ><b>Date :</b></label
                >
              </div>
              <div class="col">
                <base-input>
                  <el-date-picker
                    type="date"
                    placeholder="Date Picker"
                    v-model="datePicker"
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-3">
                <label for="customer" class="col col-form-label"
                  ><b>Customer :</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="customer"
                  placeholder="Name"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-3">
                <label for="contactNumber" class="col col-form-label"
                  ><b>Mobile :</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="contactNumber"
                  placeholder="Contact number"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-3">
                <label for="emailId" class="col col-form-label"
                  ><b>Email :</b></label
                >
              </div>
              <div class="col">
                <input
                  type="email"
                  class="form-control"
                  id="emailId"
                  placeholder="Email"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-3">
                <label for="state" class="col col-form-label"
                  ><b>Subject :</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  placeholder="Subject"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-3">
                <label for="notes" class="col col-form-label"
                  ><b>Notes :</b></label
                >
              </div>
              <div class="col form-group">
                <textarea id="notes" class="form-control" rows="7"></textarea>
              </div>
            </div>

            <div class="row p-5">
              <div class="col offset-1">
                  <base-button type="info" @click="cancelEnquiry">Cancel</base-button>
              </div>
              <div class="col offset-2">
                <router-link to="enquiry">
                  <base-button type="info">Save</base-button>
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { DatePicker } from "element-ui";
export default {
  components: {
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      datePicker: "",
    };
  },
  methods: {
    cancelEnquiry() {
      this.$router.push({path: 'enquiry'})
    }
  }
};
</script>

<style>
</style>